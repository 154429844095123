import {
    SEMATTRS_HTTP_HOST,
    SEMATTRS_HTTP_SCHEME,
    SEMATTRS_HTTP_USER_AGENT,
} from '@opentelemetry/semantic-conventions';
import { isArray, isObject, isString } from 'lodash';
import { withSafelyExecute } from './withSafelyExecute';

/**
 * Extracts URL components and returns an object containing the HTTP scheme, host, and user agent.
 *
 * @param {string} url - The URL from which to extract components.
 * @returns {Object} An object containing the URL components, or an empty object if the URL is invalid.
 */
export const getUrlComponents = (url) => {
    try {
        const urlObj = new URL(url);

        return {
            [SEMATTRS_HTTP_SCHEME]: urlObj.protocol.replace(':', ''),
            [SEMATTRS_HTTP_HOST]: urlObj?.host,
            [SEMATTRS_HTTP_USER_AGENT]: navigator?.userAgent,
        };
    } catch (error) {
        return {};
    }
};

/**
 * Removes properties with undefined values from the given object.
 *
 * @param {Object} [obj={}] - The object from which to remove undefined fields.
 * @returns {Object} A new object with undefined fields removed. Returns an empty object if the input is not an object.
 */
export function removeUndefinedFields(obj = {}) {
    if (!isObject(obj) || isArray(obj)) {
        return {};
    }

    return Object.entries(obj).reduce(
        (acc, [key, value]) => {
            if (value === undefined) {
                delete acc[key];
            }

            return acc;
        },
        { ...obj },
    );
}

/**
 * Extracts metadata from files in a FormData payload.
 *
 * @param {FormData} payload - The FormData instance containing the files.
 * @returns {Object|undefined} An object containing 'dataType' and 'filesMetadata' array, or undefined if payload is not FormData.
 */
export const getFilesMetadata = withSafelyExecute((payload) => {
    if (!(payload instanceof FormData)) {
        return undefined;
    }

    const filesMetadata = [];

    for (const pair of payload.entries()) {
        const [key, value] = pair;
        if (value instanceof File) {
            filesMetadata.push({
                key: key,
                fileName: value.name,
                fileSize: value.size,
                fileType: value.type,
            });
        }
    }

    return {
        dataType: 'FormData',
        filesMetadata,
    };
});

/**
 * Converts data to a JSON string if it is not already a string.
 *
 * @param {*} data - The data to convert.
 * @returns {string|undefined} The JSON string representation of the data, or undefined if conversion fails.
 */
export const convertToJson = withSafelyExecute((data) => (isString(data) ? data : JSON.stringify(data)));
