import { OEM_CONFIG_CONTAINER_INVALID_CONFIG } from './constants/errors';
import {
    API_CLOUD,
    CLOUD,
    DASHBOARD_URL,
    DOWNLOAD_CLOUD,
    ENV_UUID,
    MEDIA_CLOUD,
    ACCOUNT,
    AUTHA,
    DEVICE,
    OEM_TEMPLATE_MASTER_PK_ACCOUNT,
    PK_OEM,
    UPLOAD_CLOUD,
    X_APP,
    ENDPOINTS,
    EULA_URL,
    PRIVACY_POLICY_URL,
    ANDROID_RESIDENT_APP_URL,
    IOS_INSTALLER_APP_URL,
    IOS_RESIDENT_APP_URL,
    ANDROID_INSTALLER_APP_URL,
    PARTNER_PORTAL_URL,
    IOS_INSTALLER_APP_SCHEME,
    ANDROID_RESIDENT_APP_SCHEME,
    ANDROID_INSTALLER_APP_SCHEME,
    IOS_RESIDENT_APP_SCHEME,
    SUPPORT,
} from '../constants/configKeys';
import {
    MMS_MCA_ROUTER_TOKEN_EXCHANGE_LEGACY_TO_CLOUD_PATH,
    DASHBOARD_LOGIN_USER_WITH_TOKEN_PATH,
    MMS_AUTHA_AUTH_USERNAME_PATH,
    MMS_INFO_CONFIG_CONFIG_PATH,
    MMS_ACCOUNT_USER_USERS_PATH,
    MMS_ACCOUNT_KVS2_PATH,
    CLOUD_API_PATH,
    getMMSAccountInstallationPath,
    getMMSAccountInstallationsPath,
    getMMSAccount3UserPath,
    getMMSChangeUserPasswordPath,
    getMMSUserPath,
    getMMSUserPermissionsPath,
    getMMSPasswordResetPath,
    getMMSDeviceInfoPath,
    getMMSDeleteControllerPath,
    getMMSAuthPath,
    getMMSAccount3DevicePath,
    getMMSResendEmailPath,
    getMMSAccountMailingBillingPath,
    MMS_INFO_CONFIG_REGIONS_PATH,
    MMS_INFO_CONFIG_CITIES_PATH,
    MMS_ACCOUNT_PRIVACY_NOTICE_PATH,
    MMS_ACCOUNT_PRIVACY_USER_EULA_PATH,
    MMS_ACCOUNT_PRIVACY_USER_NOTICE_PATH,
    MMS_ACCOUNT_PRIVACY_EULA_PATH,
    MMS_ACCOUNT_PRIVACY_USER_CONSENT_PATH,
    MMS_ACCOUNT_PRIVACY_CONSENT_PATH,
    MCA_ROUTER_GET_SYSTEM_HISTORY,
    MMS_ACCOUNT_PRIVACY_COUNTRY_PATH,
} from '../constants/paths';

/**
 * OEMConfigContainer - used to keep OEM config and provide access to all its methods
 * */
export default class OEMConfigContainer {
    /**
     * Config object
     * */
    #config;

    constructor() {}

    /**
     * Returns string representation of a new URLSearchParams object.
     * @param {Object} obj - GET params obj
     * @return {string} a query string suitable for use in a URL WITHOUT '?' mark
     * */
    static buildGetParamsString(obj) {
        return new URLSearchParams(obj).toString();
    }

    /**
     * Returns string representation of GET params with '?' sign in the beginning
     * @param {Object} params - GET params obj
     * @return {string} a query string suitable for use in a URL WITH '?' mark
     */
    static buildGetParamsStringWithQuestionMark(params) {
        return params ? `?${OEMConfigContainer.buildGetParamsString(params)}` : '';
    }

    /**
     * Used to validate base config object
     * @param {Object} config - OEM config
     * @return boolean - validation result
     * */
    static isValidConfig(config) {
        return config && typeof config === 'object';
    }

    /**
     * Validate #setConfig params
     * @param {Object} config - config object
     * @return {undefined}
     * @throw {Error} Validation error
     * */
    static validateSetConfigParams(config) {
        if (!OEMConfigContainer.isValidConfig(config)) {
            throw new Error(OEM_CONFIG_CONTAINER_INVALID_CONFIG);
        }
    }

    /**
     * Set OEM configuration
     * @param {Object} config - Configuration object, contains OEM and environment values
     * @return {undefined}
     * */
    setConfig(config) {
        this.#config = JSON.parse(JSON.stringify(config));
    }

    /**
     * Get OEM configuration
     * @return {Object} Configuration object, contains OEM and environment values
     * */
    getConfig() {
        if (!this.isConfig()) {
            return {};
        }

        return JSON.parse(JSON.stringify(this.#config));
    }

    /**
     * Checks if there is #config
     * @return {Boolean} If #config has set and exists it will return true
     * */
    isConfig() {
        return Boolean(this.#config);
    }

    /**
     * Get OEM ID. Based on PK_OEM value
     * @return {number} OEM ID
     * */
    getOemId() {
        return this.getPkOem();
    }

    /**
     * Get PK_ACCOUNT
     * @return {string} PK_ACCOUNT
     * */
    getOemTemplateMasterPkAccount() {
        return this.#config[OEM_TEMPLATE_MASTER_PK_ACCOUNT];
    }

    /**
     * Get EULA_URL
     * @return {string} EULA URL
     * */
    getEulaUrl() {
        return this.#config[EULA_URL];
    }

    /**
     * Get PRIVACY_POLICY_URL
     * @return {string} PRIVACY_POLICY URL
     * */
    getPrivacyPolicyUrl() {
        return this.#config[PRIVACY_POLICY_URL];
    }

    /**
     * Get PK_Oem value
     * @return {number} PK_Oem value
     * */
    getPkOem() {
        return this.#config[PK_OEM];
    }

    /**
     * Get X-APP value
     * More Info:https://confluence.mios.com/display/EPD/OEM-EZLO+-+X-APP
     * @return {string} X-APP value
     * */
    getXApp() {
        return this.#config[X_APP];
    }

    getEnvironmentUuid() {
        return this.#config[ENV_UUID];
    }

    /**
     * Returns root URL to Dashboard app
     * @return {string} URL
     * */
    getDashboardUrl() {
        return this.#config[DASHBOARD_URL];
    }

    /**
     * Returns base URL for MMS Account
     * @return {string} URL
     * */
    getMMSAccountUrl() {
        return this.#config[ENDPOINTS][ACCOUNT];
    }

    /**
     * Returns base URL for MMS Autha
     * @return {string} URL
     * */
    getMMSAuthaUrl() {
        return this.#config[ENDPOINTS][AUTHA];
    }

    /**
     * Returns base URL for MMS Auth
     * @return {string} MMS Device base URL
     * */
    getMMSDeviceUrl() {
        return this.#config[ENDPOINTS][DEVICE];
    }

    /**
     * Returns Partner portal URL
     * @return {string} URL
     * */
    getPartnerPortalUrl() {
        return this.#config[PARTNER_PORTAL_URL];
    }

    /**
     * Returns URL to Dashboard automatic login page and pass GET params
     * @param {object} params - GET params object
     * @return {string} URL
     * */
    getDashboardLoginUserWithTokenUrl(params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getDashboardUrl()}/${DASHBOARD_LOGIN_USER_WITH_TOKEN_PATH}${paramsStr}`;
    }

    /**
     * Returns Login to MMS URL by username and GET params
     * @param {string} username - username, login
     * @param {Object} params - login GET params: hash, hashCS, PK_Oem
     * @return {string} URL
     * */
    getMMSLoginUrl(username, params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAuthaUrl()}/${getMMSAuthPath(username)}${paramsStr}`;
    }

    /**
     * Returns URL to get KVS data by GET params
     * @param {Object} params - GET params
     * @return {string} URL
     * */
    getMMSAccountKvs(params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_KVS2_PATH}${paramsStr}`;
    }

    getMMSAccountEulaUrl(params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_PRIVACY_EULA_PATH}${paramsStr}`;
    }

    getMMSAccountUserEulaUrl(params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_PRIVACY_USER_EULA_PATH}${paramsStr}`;
    }

    getMMSAccountNoticesUrl() {
        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_PRIVACY_NOTICE_PATH}`;
    }

    getMMSAccountUserNoticesUrl(params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_PRIVACY_USER_NOTICE_PATH}${paramsStr}`;
    }

    getMMSAccountConsentUrl() {
        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_PRIVACY_CONSENT_PATH}`;
    }

    getMMSAccountUserConsentUrl(params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_PRIVACY_USER_CONSENT_PATH}${paramsStr}`;
    }

    getMMSAccountUserCountryUrl() {
        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_PRIVACY_COUNTRY_PATH}`;
    }

    /**
     * Returns URL to delete controller by PK_Device value
     * @param {number} PK_Device - Device primary key
     * @return {string} URL
     * */
    getMMSDeleteControllerUrl(PK_Device) {
        return `${this.getMMSDeviceUrl()}/${getMMSDeleteControllerPath(PK_Device)}`;
    }

    /***
     * Returns URL to read MMS device (controller) info
     * @param {number} PK_Device - Device primary key
     * @param {object} params - GET params, should include PK_OEM key
     * @returns {string} URL
     */
    getMMSDeviceInfoUrl(PK_Device, params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSDeviceUrl()}/${getMMSDeviceInfoPath(PK_Device)}${paramsStr}`;
    }

    /**
     * Returns URL to list all devices on the account
     * @param {number} PK_Account - MMS Account primary key
     * @param {Object} params - GET params
     * @return {string} URL
     * */
    getMMSAccountDevicesUrl = (PK_Account, params) => {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${getMMSAccount3DevicePath(PK_Account)}${paramsStr}`;
    };

    /**
     * Returns URL to reset a password on the account by email address
     * @param {string} email - email address
     * @returns {string} URL
     * */
    getMMSPasswordResetUrl(email) {
        return `${this.getMMSAuthaUrl()}/${getMMSPasswordResetPath(email)}`;
    }

    /**
     * Returns URL to create user, register new user
     * @returns {string} URL
     * */
    getMMSCreateUserUrl() {
        return `${this.getMMSAuthaUrl()}/${MMS_AUTHA_AUTH_USERNAME_PATH}`;
    }

    /**
     * Returns URL to manage user on the account by PK_Account_Parent and PK_User values.
     * @param {number} PK_Account_Parent - MMS Account Parent primary key
     * @param {number} PK_User - MMS User primary key
     * @return {string} URL
     * */
    getMMSAccountAccountParentUserUrl(PK_Account_Parent, PK_User) {
        return `${this.getMMSAccountUrl()}/${getMMSAccount3UserPath(PK_Account_Parent, PK_User)}`;
    }

    /**
     * Returns URL to DELETE the user on the account by PK_Account_Parent and PK_User values.
     * @param {number} PK_Account_Parent - MMS Account Parent primary key
     * @param {number} PK_User - MMS User primary key
     * @return {string} URL
     * */
    getMMSDeleteUserUrl(PK_Account_Parent, PK_User) {
        return this.getMMSAccountAccountParentUserUrl(PK_Account_Parent, PK_User);
    }

    /**
     * Returns URL to READ all MMS Users
     * @return {string} URL
     * */
    getMMSUsersUrl() {
        return `${this.getMMSAccountUrl()}/${MMS_ACCOUNT_USER_USERS_PATH}`;
    }

    /**
     * Path, used to manage MMS User permissions.
     * @param {number} PK_User - MMS User primary key
     * @return {string} URL
     * */
    getMMSUserPermissionsUrl(PK_User) {
        return `${this.getMMSAuthaUrl()}/${getMMSUserPermissionsPath(PK_User)}`;
    }

    /**
     * Returns URL to get user info/details about MMS User on MMS Account
     * @param {number} PK_User - MMS User primary key
     * @param {object} [params] - GET params, include PK_OEM key
     * @return {string} URL
     * */
    #getMMSUserUrl(PK_User, params) {
        const getParamsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${getMMSUserPath(PK_User)}${getParamsStr}`;
    }

    /**
     * Returns URL to send a verification email to the user's email
     * @param {number} PK_User - MMS User primary key
     * @return {string} URL
     * */
    #getMMSResendEmailUrl(PK_User) {
        return `${this.getMMSAccountUrl()}/${getMMSResendEmailPath(PK_User)}`;
    }

    /**
     * Returns URL to get user info/details about MMS User on MMS Account
     * @param {number} PK_User - MMS User primary key
     * @param {object} params - GET params, should include PK_OEM key
     * @return {string} URL
     * */
    getMMSGetAccountUserUrl(PK_User, params) {
        return this.#getMMSUserUrl(PK_User, params);
    }

    /**
     * Returns URL to send a verification email to the user's email
     * @param {number} PK_User - MMS User primary key
     * @return {string} URL
     * */
    getMMSGetResendEmailUrl(PK_User) {
        return this.#getMMSResendEmailUrl(PK_User);
    }

    /**
     * Returns URL to update the MMS user
     * @param {number} PK_User - MMS User primary key
     * @param {object} [params] - GET params, should include PK_OEM key
     * @return {string} URL
     * */
    getMMSUpdateAccountUserUrl(PK_User) {
        return this.#getMMSUserUrl(PK_User);
    }

    /**
     * Returns URL to change password on the account.
     * @param {string} username - username, original user login
     * @param {object} params - GET params, e.g. new password, hash, hashCS etc.
     * @return {string} URL
     * */
    getMMSChangeUserPasswordUrl(username, params) {
        const paramsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAuthaUrl()}/${getMMSChangeUserPasswordPath(username)}${paramsStr}`;
    }

    /**
     * Returns URL to manage installation on the account by PK_Account, PK_Installation values.
     * @param {number} PK_Account - MMS Account primary key
     * @param {number} PK_Installation - MMS Installation primary key
     * @return {string} URL
     * */
    getMMSInstallationUrl(PK_Account, PK_Installation) {
        return `${this.getMMSAccountUrl()}/${getMMSAccountInstallationPath(PK_Account, PK_Installation)}`;
    }

    /**
     * Returns URL to READ all MMS Installations on MMS Account
     * @param {number} PK_Account - MMS Account primary key
     * @return {string} URL
     * */
    getMMSInstallationsUrl(PK_Account) {
        return `${this.getMMSAccountUrl()}/${getMMSAccountInstallationsPath(PK_Account)}`;
    }

    /**
     * Returns URL to manage Mailing and Billing addresses on the account by PK_Account.
     * @param {number} PK_Account - MMS Account primary key
     * @return {string} URL
     * */
    getMMSMailingBillingUrl(PK_Account) {
        return `${this.getMMSAccountUrl()}/${getMMSAccountMailingBillingPath(PK_Account)}`;
    }

    /**
     * Returns URL for MMS Config endpoint
     * @param {Object} params - GET params
     * @return {string} URL
     * */
    getMMSConfigUrl(params) {
        const getParamsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_INFO_CONFIG_CONFIG_PATH}${getParamsStr}`;
    }

    /**
     * Returns URL for MMS Config regions endpoint
     * @param {Object} params - GET params
     * @return {string} URL
     * */
    getMMSConfigRegionsUrl(params) {
        const getParamsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_INFO_CONFIG_REGIONS_PATH}${getParamsStr}`;
    }

    /**
     * Returns URL for MMS Config cities endpoint
     * @param {Object} params - GET params
     * @return {string} URL
     * */
    getMMSConfigCitiesUrl(params) {
        const getParamsStr = OEMConfigContainer.buildGetParamsStringWithQuestionMark(params);

        return `${this.getMMSAccountUrl()}/${MMS_INFO_CONFIG_CITIES_PATH}${getParamsStr}`;
    }

    /**
     * Returns URL to get countries list from MMS
     * @returns {string} URL
     * */
    getMMSCountriesUrl() {
        const params = { Country: 1, PK_Oem: this.getPkOem() };

        return this.getMMSConfigUrl(params);
    }

    /**
     * Returns URL to get regions list from MMS
     * @returns {string} URL
     * */
    getMMSRegionsUrl(PK_Country) {
        const params = { PK_Country };

        return this.getMMSConfigRegionsUrl(params);
    }

    /**
     * Returns URL to get cities list from MMS
     * @returns {string} URL
     * */
    getMMSCitiesUrl(PK_Country, PK_Region) {
        const params = { PK_Country, PK_Region };

        return this.getMMSConfigCitiesUrl(params);
    }

    /**
     * Returns URL to Cloud endpoint
     * @returns {string} URL
     * */
    getCloudUrl() {
        return this.#config[ENDPOINTS][CLOUD];
    }

    /**
     * Returns URL to API Cloud endpoint
     * @returns {string} URL
     * */
    getCloudApiUrl() {
        return `${this.#config[ENDPOINTS][API_CLOUD]}/${CLOUD_API_PATH}`;
    }

    /**
     * Returns URL to Upload Cloud endpoint
     * @returns {string} URL
     * */
    getCloudUploadUrl() {
        return this.#config[ENDPOINTS][UPLOAD_CLOUD];
    }

    /**
     * Returns URL to Download Cloud endpoint
     * @param {object} params - GET params
     * @returns {string} URL
     * */
    getCloudDownloadBaseUrl() {
        return this.#config[ENDPOINTS][DOWNLOAD_CLOUD];
    }

    /**
     * Returns URL to Download Cloud endpoint
     * @param {object} params - GET params
     * @returns {string} URL
     * */
    getCloudDownloadUrl(params) {
        const paramsStr = params ? OEMConfigContainer.buildGetParamsStringWithQuestionMark(params) : '';

        return `${this.getCloudDownloadBaseUrl()}/${paramsStr}`;
    }

    /**
     * Returns URL to Media Cloud endpoint
     * @returns {string} URL
     * */
    getCloudMediaUrl() {
        return this.#config[ENDPOINTS][MEDIA_CLOUD];
    }

    /**
     * Return URL to exchange MMS signatures to Cloud token
     * @param {Object} [params] - GET params: PK_User
     * @returns {string} URL
     * */
    getCloudTokenExchangeUrl(params) {
        const paramsStr = params ? OEMConfigContainer.buildGetParamsStringWithQuestionMark(params) : '';

        return `${this.getCloudUrl()}/${MMS_MCA_ROUTER_TOKEN_EXCHANGE_LEGACY_TO_CLOUD_PATH}${paramsStr}`;
    }

    getSystemHistory(params) {
        return `${this.getCloudUrl()}/${MCA_ROUTER_GET_SYSTEM_HISTORY}${params}`;
    }

    /**
     * Returns Android Resident mobile application URL
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getAndroidResidentAppUrl() {
        return this.#config[ANDROID_RESIDENT_APP_URL];
    }

    /**
     * Returns Android Installer mobile application URL
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getAndroidInstallerAppUrl() {
        return this.#config[ANDROID_INSTALLER_APP_URL];
    }

    /**
     * Returns IOS Resident mobile application URL
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getIosResidentAppUrl() {
        return this.#config[IOS_RESIDENT_APP_URL];
    }

    /**
     * Returns IOS Installer mobile application URL
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getIosInstallerAppUrl() {
        return this.#config[IOS_INSTALLER_APP_URL];
    }

    /**
     * Returns IOS Installer mobile application Scheme
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getIosInstallerScheme() {
        return this.#config[IOS_INSTALLER_APP_SCHEME];
    }

    /**
     * Returns IOS Installer mobile application Scheme
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getIosResidentScheme() {
        return this.#config[IOS_RESIDENT_APP_SCHEME];
    }

    /**
     * Returns IOS Installer mobile application Scheme
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getAndroidInstallerScheme() {
        return this.#config[ANDROID_INSTALLER_APP_SCHEME];
    }

    /**
     * Returns IOS Installer mobile application Scheme
     * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
     * @returns {string}
     * */
    getAndroidResidentScheme() {
        return this.#config[ANDROID_RESIDENT_APP_SCHEME];
    }

    /**
     * Returns Support config
     * @returns {object | undefined} Support config
     * @example
     * {
     *    "phone": "+18448180573",
     *    "email": "partner-support@ezlo.com",
     *    "website": "https://support.ezlo.com"
     * }
     * */
    getSupportConfig() {
        return this.#config[SUPPORT];
    }
}
