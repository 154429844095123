import { convertToJson, removeUndefinedFields } from './shared';
import { withSafelyExecute } from './withSafelyExecute';
import { store } from 'store/configureStore';

/**
 * Safely retrieves the user's UUID from the Redux store.
 *
 * @returns {string|undefined} The user's UUID, or undefined if not available.
 */
export const getUserUuid = withSafelyExecute(() => {
    const state = store.getState();

    return state?.account?.data?.user_uuid;
});

/**
 * Safely retrieves the controller's UUID from the Redux store based on the provided serial number.
 *
 * @param {string} serial - The serial number of the controller.
 * @returns {string|undefined} The controller's UUID, or undefined if not found.
 */
export const getControllerUuid = withSafelyExecute((serial) => {
    const state = store.getState();

    return state?.ezlos?.items?.find((item) => item.PK_Device == serial || item.serial == serial)?.controller_uuid;
});

/**
 * Generates dynamic WebSocket log attributes by extracting relevant information.
 *
 * @param {Object} payload - The payload object.
 * @param {*} [payload.data] - The data to be logged.
 * @param {*} [payload.params] - Additional parameters to be logged.
 * @param {string|number} payload.serial - The serial number of the controller.
 * @param {*} [payload.error] - An error object to be logged.
 * @returns {Object} An object containing the dynamic log attributes.
 */
export const getDynamicWSLogAttributes = ({ data, params, serial, error }) => {
    return removeUndefinedFields({
        user_uuid: getUserUuid(),
        controller_uuid: getControllerUuid(serial),
        result: convertToJson(data),
        params: convertToJson(params),
        error: convertToJson(error),
    });
};
