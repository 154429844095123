import isFunction from 'lodash/isFunction';

/**
 * Safely executes a function, handling any exceptions.
 *
 * @param {Function} func - The function to execute.
 * @param {Array} args - The arguments to pass to the function.
 * @param {Function} [errorHandler] - Optional function to handle errors.
 * @returns {*} The result of the function execution, or undefined if an exception occurred.
 */
export function safelyExecute(func, args, errorHandler) {
    try {
        return func(...args);
    } catch (e) {
        if (isFunction(errorHandler)) {
            return errorHandler(e);
        }
    }
}

/**
 * Returns a new function that safely executes the original function.
 *
 * @param {Function} func - The original function to execute safely.
 * @param {Function} [errorHandler] - Optional function to handle errors.
 * @returns {Function} A wrapper function that safely executes the original function.
 */
export const withSafelyExecute =
    (func, errorHandler) =>
    (...args) =>
        safelyExecute(func, args, errorHandler);
