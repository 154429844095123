import isObject from 'lodash/isObject';

/**
 * Adds OpenTelemetry trace ID and span ID to the headers as an ETag.
 *
 * Modifies the `headers` object by setting the `ETag` property to a string containing
 * the `traceId` and `spanId` from the provided `span`, joined by a comma.
 *
 * @param {Object} config - Request config object.
 * @param {Object} [config.headers] - The headers object to which the ETag will be added.
 * @param {Object} span - The OpenTelemetry span object containing the trace context.
 * @param {Function} span.spanContext - A function that returns the span context.
 * @returns {void}
 */
export function setOtToHeaders(config, span) {
    const { traceId, spanId } = span?.spanContext?.() || {};

    if (traceId && spanId) {
        const ETag = [traceId, spanId].join(', ');
        isObject(config.headers) ? (config.headers.ETag = ETag) : (config.headers = { ETag });
    }
}
