import { tracer } from '../../tracers';
import { SEMATTRS_HTTP_METHOD, SEMATTRS_HTTP_URL } from '@opentelemetry/semantic-conventions';
import { getUrlComponents } from '../../utils';
import otlFetchStart from './otlFetchStart';
import otlFetchSuccess from './otlFetchSuccess';
import otlFetchReject from './otlFetchReject';

function fetchWithOtl(...args) {
    const [url, { method }] = args;

    return tracer.startActiveSpan(
        `Fetch HTTP ${method} request`,
        {
            attributes: {
                [SEMATTRS_HTTP_URL]: url,
                [SEMATTRS_HTTP_METHOD]: method,
                ...getUrlComponents(url),
            },
        },
        (span) => {
            otlFetchStart(span, ...args);

            return fetch(...args)
                .then(async (response) => {
                    const responseData = await response.json();
                    response.responseData = responseData;

                    // based error case || error case with 200 code
                    if (!response.ok || responseData.status === 0) {
                        return Promise.reject(response);
                    }

                    return response;
                })
                .then((response) => {
                    otlFetchSuccess(span, url, response);

                    return Promise.resolve(response);
                })
                .catch((error) => {
                    otlFetchReject(span, url, error);

                    return Promise.reject(error);
                })
                .finally(() => {
                    span.end();
                });
        },
    );
}

export default fetchWithOtl;
