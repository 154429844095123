/**
 * Actual version of WebUI.
 * <br />
 * Given a version number MAJOR.MINOR.PATCH, increment the: <br />
 * MAJOR - version when you make incompatible API changes. <br />
 * MINOR - version when you add functionality in a backwards compatible manner. <br />
 * PATCH - version when you make backwards compatible bug fixes. <br />
 * More info {@link https://semver.org/ here}
 * */
export const WEBUI_VERSION = '2.9.1';

export const REFRESH_FUNCTION_DELAY = 82800000;

/**
 * Defined by Cloud team, boolean + integer
 * */
export const BOOLINT = { TRUE: 1, FALSE: 0 };
export const VERSIONS = { FIRST: '1' };
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
/**
 * Enumeration of pagination arrow types for navigation.
 *
 * @readonly
 * @enum {string}
 * @property {string} PREV - Indicates the 'previous' pagination arrow.
 * @property {string} NEXT - Indicates the 'next' pagination arrow.
 */
export const PAGINATION_ARROWS = {
    PREV: 'prev',
    NEXT: 'next',
};
/**
 * Constant representing the 'left' direction.
 *
 * @constant
 * @type {string}
 */
export const LEFT = 'left';
/**
 * Constant representing the 'right' direction.
 *
 * @constant
 * @type {string}
 */
export const RIGHT = 'right';
/**
 * Represents the constant key for a generic value.
 *
 * @constant
 * @type {string}
 */
export const VALUE = 'value';
export const ZERO = 0;
