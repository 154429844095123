import isFunction from 'lodash/isFunction';
import initialWsOtl from './initialWsOtl';
import { context, trace } from '@opentelemetry/api';

/**
 * Wraps a callback function with OpenTelemetry tracing context for WebSocket operations.
 *
 * @param {Object} payload - The payload data for the WebSocket operation.
 * @param {string|number} payload.serial - The serial number of the hub.
 * @param {string} payload.method - json-rpc method name.
 * @param {Object} payload.params - json-rpc params.
 * @param {Function} cb - The callback function to be executed, expected to return a Promise.
 * @returns {Promise} The result of the callback function, potentially wrapped with tracing logic.
 */
export function withOtl(payload, cb) {
    const { span, onSuccess, onError } = initialWsOtl(payload) || {};

    if (!span || !isFunction(onSuccess) || !isFunction(onError)) {
        return cb();
    }

    return context.with(trace.setSpan(context.active(), span), async () => cb().then(onSuccess).catch(onError));
}
