import { withSafelyExecute } from './withSafelyExecute';
import getOtIds from './getOtIds';
import { isString } from 'lodash';

/**
 * Retrieves OpenTelemetry trace IDs and returns them in an object with custom keys.
 *
 * @param {string} traceIdKey - The key to use for the trace ID in the returned object.
 * @param {string} spanIdKey - The key to use for the span ID in the returned object.
 * @returns {Object} An object containing the trace ID and span ID with the specified keys.
 * @throws {Error} If the active span is missing, otIds are not available, or keys are invalid.
 */
function getOt(traceIdKey, spanIdKey) {
    if (!isString(traceIdKey) || !isString(spanIdKey)) {
        throw new Error('Passed incorrect arguments to getOt function');
    }
    const otIds = getOtIds();
    if (!otIds) {
        throw new Error('Missing active span');
    }

    return {
        [traceIdKey]: otIds.traceId,
        [spanIdKey]: otIds.spanId,
    };
}

/**
 * Retrieves OpenTelemetry trace IDs and returns them in an object with custom keys.
 *
 * @param {string} traceIdKey - The key to use for the trace ID in the returned object.
 * @param {string} spanIdKey - The key to use for the span ID in the returned object.
 * @returns {Object | undefined} An object containing the trace ID and span ID with the specified keys or undefined If the active span is missing, otIds are not available, or keys are invalid.
 */
export default withSafelyExecute(getOt);
