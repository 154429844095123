import { trace } from '@opentelemetry/api';
import { withSafelyExecute } from './withSafelyExecute';

/**
 * Retrieves the OpenTelemetry trace ID and span ID from the current active span.
 *
 * @returns {{traceId: string, spanId: string}} An object containing the trace ID and span ID.
 * @throws {Error} If there is no active span or if trace ID or span ID is missing.
 */
export function getOtIds() {
    const { traceId, spanId } = trace.getActiveSpan().spanContext();

    if (!traceId || !spanId) {
        throw new Error('Missing active span');
    }

    return { traceId, spanId };
}

/**
 * Retrieves the OpenTelemetry trace ID and span ID from the current active span.
 *
 * @returns {{traceId: string, spanId: string} | undefined} An object containing the trace ID and span ID or undefined If there is no active span or if trace ID or span ID is missing.
 */
export default withSafelyExecute(getOtIds);
