import { tracer } from '../../tracers';
import { SEMATTRS_HTTP_METHOD, SEMATTRS_HTTP_URL } from '@opentelemetry/semantic-conventions';
import { getUrlComponents, setOtToHeaders } from '../../utils';
import { withSafelyExecute } from '../../utils/withSafelyExecute';
import { context, trace } from '@opentelemetry/api';
import { log } from '../../logging';
import { EVENTS, SEVERITY_TEXT, STAGES } from '../../constants';
import { getDynamicHttpLogAttributes } from '../../utils/getDynamicHttpLogAttributes';
// import { incrementMetricsCounter } from '../../metrics';

/**
 * Handles the start of an HTTP request by creating a span, logging the request, and incrementing metrics.
 *
 * @param {Object} config - The configuration object for the HTTP request.
 * @param {string} config.url - The URL of the request.
 * @param {string} config.method - The HTTP method of the request (e.g., 'GET', 'POST').
 * @param {Object} [config.headers] - The headers of the request.
 * @param {*} [config.data] - The data payload of the request.
 * @param {Object} [config.logData] - Additional data to include in the log.
 */
function otlHandleStartRequest(config) {
    const { url, method, headers, data, logData } = config || {};
    config.span = tracer.startSpan(`HTTP ${config.method.toUpperCase()} request`, {
        attributes: {
            [SEMATTRS_HTTP_URL]: url,
            [SEMATTRS_HTTP_METHOD]: method.toUpperCase(),
            ...getUrlComponents(url),
        },
    });
    config.context = trace.setSpan(context.active(), config.span);
    setOtToHeaders(config, config.span);

    context.with(config.context, () => {
        log(`Starting request to ${url}`, SEVERITY_TEXT.INFO, {
            url,
            method,
            ...getDynamicHttpLogAttributes({ url, stage: STAGES.REQUEST, headers, params: data }),
            ...logData?.attributes,
        });
    });
    config.span.addEvent(EVENTS.REQUEST_STARTED);
    // incrementMetricsCounter(url, method, data);
}

export default withSafelyExecute(otlHandleStartRequest);
