import oemManager from 'services/oem';
import { CLOUD, MMS } from '../constants';
import { withSafelyExecute } from './withSafelyExecute';

/**
 * Checks if a given URL is an MMS URL.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} True if the URL is an MMS URL, false otherwise.
 */
export const isMmsUrl = withSafelyExecute((url) => {
    if (oemManager.oem.isConfig()) {
        const mmsUrls = [
            oemManager.oem.getMMSAuthaUrl(),
            oemManager.oem.getMMSAccountUrl(),
            oemManager.oem.getMMSDeviceUrl(),
        ];

        return mmsUrls?.some((mmsUrl) => url.includes(mmsUrl));
    }
});

/**
 * Checks if a given URL is a Cloud URL.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} True if the URL is a Cloud URL, false otherwise.
 */
export const isCloudUrl = withSafelyExecute((url) => {
    if (oemManager.oem.isConfig()) {
        const cloudUrls = [
            oemManager.oem.getCloudUrl(),
            oemManager.oem.getCloudApiUrl(),
            oemManager.oem.getCloudUploadUrl(),
            oemManager.oem.getCloudDownloadBaseUrl(),
            oemManager.oem.getCloudMediaUrl(),
        ];

        return cloudUrls?.some((cloudUrl) => url.includes(cloudUrl));
    }
});

/**
 * Determines the type of a given URL based on whether it is a Cloud or MMS URL and the provided stage.
 *
 * @param {string} url - The URL to evaluate.
 * @param {'request'|'response'} stage - The stage of the request. Should be one of STAGES.REQUEST or STAGES.RESPONSE.
 * @returns {string|undefined} The type of the URL in the format `${type}-${stage}`, or undefined if not recognized.
 */
export const getType = withSafelyExecute((url, stage) => {
    if (isCloudUrl(url)) {
        return `${CLOUD}-${stage}`;
    }

    if (isMmsUrl(url)) {
        return `${MMS}-${stage}`;
    }
});
