import { logAndTraceErrorResponse } from './logAndTraceErrorResponse';
import { context, SpanStatusCode } from '@opentelemetry/api';
import { EVENTS, SEVERITY_TEXT, STAGES } from '../../constants';
import { log } from '../../logging';
import { getDynamicHttpLogAttributes } from '../../utils/getDynamicHttpLogAttributes';
import { SEMATTRS_HTTP_STATUS_CODE } from '@opentelemetry/semantic-conventions';
import { HTTP_STATUS_TEXT } from '../../constants/attributeNames';
import { withSafelyExecute } from '../../utils/withSafelyExecute';

/**
 * Handles a successful HTTP response, adding events and logging as needed.
 *
 * @param {Object} response - The Axios response object.
 * @param {Object} response.config - The Axios request configuration.
 * @param {Object} response.config.span - The OpenTelemetry span associated with the request.
 * @param {Object} response.config.context - The OpenTelemetry context associated with the request.
 * @param {Object} [response.config.logData] - Additional data to include in the log.
 * @param {string} response.config.url - The URL of the request.
 * @param {number} response.status - The HTTP status code.
 * @param {string} response.statusText - The HTTP status text.
 * @param {Object} response.data - The response data.
 * @returns {Object} The response object.
 */
function otlHandleSuccessResponse(response) {
    const { span, context: spanContext, logData, url } = response.config || {};
    const { status, statusText, data } = response || {};
    if (!span || !spanContext) {
        return;
    }

    // error case with 200 code
    if (data.status === 0) {
        logAndTraceErrorResponse({
            spanContext,
            span,
            url,
            logData,
            statusText,
            status,
            error: JSON.stringify(data.data),
        });

        return;
    }

    context.with(spanContext, () => {
        span.addEvent(EVENTS.RESPONSE_RECEIVED);
        log(`Request to ${url} succeeded`, SEVERITY_TEXT.INFO, {
            url,
            status,
            ...getDynamicHttpLogAttributes({ url, stage: STAGES.RESPONSE, data }),
            ...logData?.attributes,
        });

        span.setAttributes({
            [SEMATTRS_HTTP_STATUS_CODE]: status,
            [HTTP_STATUS_TEXT]: statusText,
        });
        span.setStatus({ code: SpanStatusCode.OK });
        span.end();
    });
}

export default withSafelyExecute(otlHandleSuccessResponse);
