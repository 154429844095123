import { LoggerProvider, BatchLogRecordProcessor } from '@opentelemetry/sdk-logs';
import { Resource } from '@opentelemetry/resources';
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http';
import { diag, DiagConsoleLogger, DiagLogLevel } from '@opentelemetry/api';
import * as otelLogs from '@opentelemetry/api-logs';
import packageJson from '../../../package.json';
import oemManager from 'services/oem';
import { getEnvKey } from 'constants/envs';
import { OPEN_TELEMETRY_LOGS_URL } from './constants/api';
import { SEVERITY_TEXT } from './constants';

const SERVICE_NAME = packageJson.name;

class OTLLogs {
    static collectorOptions = {
        url: OPEN_TELEMETRY_LOGS_URL,
        headers: {},
    };

    static globalLogger = null;

    constructor() {
        diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.INFO);
    }

    static getLogger() {
        return OTLLogs.globalLogger;
    }

    static getSeverityNumberByText(severityText) {
        return otelLogs.SeverityNumber[severityText.toUpperCase()] || otelLogs.SeverityNumber.UNSPECIFIED;
    }

    initializeOpenTelemetryLogger() {
        const oem_id = oemManager.oem.getOemId();
        const environment = getEnvKey();

        const loggerProvider = new LoggerProvider({
            resource: new Resource({
                [SEMRESATTRS_SERVICE_NAME]: SERVICE_NAME,
                oem_id,
                environment,
            }),
        });

        loggerProvider.addLogRecordProcessor(
            new BatchLogRecordProcessor(new OTLPLogExporter(OTLLogs.collectorOptions), {
                maxExportBatchSize: 6,
                scheduledDelayMillis: 5000,
                exportTimeoutMillis: 30000,
            }),
        );

        otelLogs.logs.setGlobalLoggerProvider(loggerProvider);
        OTLLogs.globalLogger = otelLogs.logs.getLogger(SERVICE_NAME);
    }

    log(body, severityText = SEVERITY_TEXT.DEBUG, attributes) {
        try {
            const logger = OTLLogs.globalLogger;
            if (!logger) {
                return;
            }

            logger.emit({
                // If there is an active span in the context, spanId and traceId will be added automatically.
                body,
                severityNumber: OTLLogs.getSeverityNumberByText(severityText),
                severityText,
                attributes,
            });
        } catch (e) {
            diag.error('Error emitting log:', e);
        }
    }
}

const otlLogsInstance = new OTLLogs();

export const { initializeOpenTelemetryLogger, log } = otlLogsInstance;
