import { API_CLOUD_EZLO_REQUEST_URL } from '../../../constants/URLs.js';
import axios from 'axios';

export const setBounceGetParams = (key) => {
    return JSON.stringify({
        call: 'bounce_get',
        params: {
            key,
        },
    });
};

export const getUserAuthData = (params) => {
    const { raw } = params.data;
    const rawResponse = JSON.parse(raw);

    return {
        data: {
            path: '',
            Server_Account: rawResponse.Server_Account,
            Server_Account_Alt: rawResponse.Server_Account_Alt,
            Identity: rawResponse.mmsAuth,
            IdentitySignature: rawResponse.mmsAuthSig,
            page: rawResponse.page,
            PK_User: rawResponse.PK_User,
            language: rawResponse.language,
        },
    };
};

export const setBounceGetRequest = (body) => {
    return axios
        .post(API_CLOUD_EZLO_REQUEST_URL(), body, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
        .then(({ data }) => {
            if (Object.keys(data.data).length === 0 || data.status === 0) {
                window.location.assign('/');
            } else {
                return data;
            }
        })
        .catch((err) => {
            throw new Error(err);
        });
};
