import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { FROM_DATE, NEXT, PAGE_STEP, PATHS, PREV, TIMESTAMP_AFTER, TIMESTAMP_BEFORE, TO_DATE } from './constants';
import {
    EZLOGIC_TITLE_FROM_FIELD_ERROR_TEXT,
    EZLOGIC_TITLE_TO_FIELD_ERROR_TEXT,
} from '../../../constants/language_tokens';
import { isObject } from 'lodash';
import { HUB_FEATURES_NAMES, HUB_NAMES_KEYS_BY_HUB_TYPE } from 'services/hub';
import { isControllerTypeMatch } from '../EzloGroups/utils';
import { MESHBOT_TYPES } from '../EzloMeshbots/constants';

export const formatBackupTimestamp = (timestamp = 0, offset = 0) =>
    moment(new Date(Number(timestamp)))
        .utc()
        .add(offset, 'seconds')
        .format('DD MMM YYYY, h:mm a');

export const convertTimestampToDateString = (
    timestamp,
    timeZone,
    format = 'DD MMM YYYY, h:mm a ([UTC] Z)',
    withTimeZoneName = true,
    locale = 'en',
) => {
    // Create a moment object with the provided timestamp
    const momentObject = momentTimezone(Number(timestamp));
    momentObject.locale(locale);

    if (timeZone) {
        // Set the time zone for the moment object
        momentObject.tz(timeZone);
    }

    if (withTimeZoneName && timeZone) {
        // Format the moment object to the desired format (DD MMM YYYY, h:mm a  ([GMT] Z) timeZone)
        return `${momentObject.format(format)} ${timeZone}`;
    }

    // Format the moment object to the desired format (DD MMM YYYY, h:mm a z)
    return momentObject.format(format);
};
export const getCurrentTimestamp = () => {
    const currentDate = new Date();

    return currentDate.getTime();
};

/**
 * Checks if the provided hub supports backups.
 *
 * This function determines whether the selected controller supports backups based on the provided hub feature list.
 * It first checks if backups are supported in the general hub features and then excludes support for EzloPi controllers.
 *
 * @function
 * @param {string} selectedController - The selected controller type.
 * @param {Object} hubFeaturesList - The hub feature list to check for backups support.
 * @returns {boolean} True if the hub supports backups, false otherwise.
 *
 * @example
 * // Usage
 * const selectedControllerData = 'someControllerType';
 * const hubFeatures = { backups: { /* ...some field  },  ...otherFeatures  };
 * const supportsBackups = isHubBackupSupported(selectedControllerData, hubFeatures);
 * // Result: true or false
 */
export const isHubBackupSupported = (selectedControllerData, hubFeaturesList) => {
    const isBackupSupported = isBackupsFeatureSupported(hubFeaturesList, selectedControllerData?.isConnected);
    const isEzlopiController = isControllerTypeMatch(selectedControllerData, MESHBOT_TYPES.EZLOPI);
    if (isEzlopiController) {
        return false;
    }

    return isBackupSupported;
};
/**
 * Checks if the provided hub feature list contains support for backups.
 *
 * This function verifies if the given hub feature list is an object and has the 'backups' feature,
 * only if the controller is online.
 *
 * @function
 * @param {Object} hubFeaturesList - The hub feature list to check.
 * @param {boolean} isControllerOnline - Indicates if the controller is online.
 * @returns {boolean} True if the hub supports backups or if the controller is offline, false otherwise.
 *
 * @example
 * // Usage
 * const hubFeatures = { backups: { // ...some field}, ...otherFeatures };
 * const supportsBackups = isBackupsFeatureSupported(hubFeatures, true);
 * // Result: true
 */
export const isBackupsFeatureSupported = (hubFeaturesList, isControllerOnline) => {
    if (isControllerOnline) {
        return isObject(hubFeaturesList) && hubFeaturesList.hasOwnProperty(HUB_FEATURES_NAMES.BACKUPS);
    } else {
        return true;
    }
};
/**
 * Retrieves the hub feature list for a specific controller from the Ezlo data.
 *
 * This function fetches the feature list for the specified controller from the Ezlo data.
 *
 * @function
 * @param {string} currentController - The serial number of the current controller.
 * @param {Object} ezlo - The Ezlo data object containing information about controllers.
 * @returns {Object} The feature list for the specified controller, or an empty object if not found.
 *
 * @example
 * // Usage
 * const currentControllerSerial = '90002123';
 * const ezloData = { data: { 90002123: { features: { backups: { // ...some field}, ...otherFeatures } } } };
 * const featureList = getHubFeaturesList(currentControllerSerial, ezloData);
 * // Result: { backups: { // ...some field}, ...otherFeatures }
 */
export const getHubFeaturesList = (currentController, ezlo) => {
    const currentControllerData = ezlo.data[currentController];

    return currentControllerData?.features || {};
};
/**
 * Get the current date in the specified time zone or the default time zone.
 *
 * This function returns the current date in the provided time zone or, if no time zone is specified, in the default
 * time zone. It uses the moment-timezone library to format the date as 'YYYY-MM-DD'.
 *
 * @param {string} [timeZone] - The time zone in which to obtain the current date.
 * @returns {string} The current date in 'YYYY-MM-DD' format.
 *
 * @example
 * const timeZone = 'America/New_York';
 * const currentDate = getCurrentDateInTimeZone(timeZone);
 * // Result: '2023-10-27' (current date in the 'America/New_York' time zone)
 */
export function getCurrentDateInTimeZone(timeZone) {
    if (timeZone) {
        return momentTimezone.tz(timeZone).format('YYYY-MM-DD');
    }

    return moment().format('YYYY-MM-DD');
}
/**
 * Get the initial date fields with the current date in the specified time zone.
 *
 * This function constructs and returns an object with initial date fields. It sets the 'to_date' field to the current
 * date in the specified time zone, or in the default time zone if none is provided. The date is formatted as 'YYYY-MM-DD'.
 *
 * @param {string} [timeZone] - The time zone in which to obtain the current date.
 * @returns {Object} An object with initial date fields, including 'to_date'.
 *
 * @example
 * const timeZone = 'America/New_York';
 * const initialDateFields = getInitialDateFields(timeZone);
 * // Result: { to_date: '2023-10-27' } (current date in the 'America/New_York' time zone)
 */
export const getInitialDateFields = (timeZone) => {
    const currentDateInTimeZone = getCurrentDateInTimeZone(timeZone);
    const fields = {};
    if (currentDateInTimeZone) {
        fields[TO_DATE] = currentDateInTimeZone;
    }

    return fields;
};

/**
 * Get the end of day timestamp for the specified date in the given time zone or default time zone.
 *
 * This function calculates and returns the end of day timestamp (in milliseconds) for the specified date in the provided
 * time zone or, if no time zone is specified, in the default time zone. It uses the moment-timezone library to handle
 * time zone conversions and formatting.
 *
 * @param {string} dateString - The date in 'YYYY-MM-DD' format for which to calculate the end of day timestamp.
 * @param {string} [timeZone] - The time zone in which to calculate the end of day timestamp.
 * @returns {number|null} The end of day timestamp in milliseconds or null if dateString is not provided.
 *
 * @example
 * const dateString = '2023-10-27';
 * const timeZone = 'America/New_York';
 * const endOfDayTimestamp = getEndOfDayTimestamp(dateString, timeZone);
 * // Result: 1666886399999 (end of the day timestamp for '2023-10-27' in the 'America/New_York' time zone)
 */
export function getEndOfDayTimestamp(dateString, timeZone) {
    if (!dateString) {
        return null;
    }

    if (timeZone) {
        const formattedDate = momentTimezone.tz(dateString, 'YYYY-MM-DD', timeZone);
        // Set the time to the end of the day (23:59:59)
        formattedDate.endOf('day');

        return formattedDate.valueOf(); // Returns the timestamp in milliseconds
    }
    const formattedDate = moment(dateString, 'YYYY-MM-DD');
    // Set the time to the end of the day (23:59:59)
    formattedDate.endOf('day');

    return formattedDate.valueOf(); // Returns the timestamp in milliseconds
}
/**
 * Get the start of day timestamp for the specified date in the given time zone or default time zone.
 *
 * This function calculates and returns the start of day timestamp (in milliseconds) for the specified date in the provided
 * time zone or, if no time zone is specified, in the default time zone. It uses the moment-timezone library to handle
 * time zone conversions and formatting.
 *
 * @param {string} dateString - The date in 'YYYY-MM-DD' format for which to calculate the start of day timestamp.
 * @param {string} [timeZone] - The time zone in which to calculate the start of day timestamp.
 * @returns {number|null} The start of day timestamp in milliseconds or null if dateString is not provided.
 *
 * @example
 * const dateString = '2023-10-27';
 * const timeZone = 'America/New_York';
 * const startOfDayTimestamp = getStartOfDayTimestamp(dateString, timeZone);
 * // Result: 1666800000000 (start of the day timestamp for '2023-10-27' in the 'America/New_York' time zone)
 */
export function getStartOfDayTimestamp(dateString, timeZone) {
    if (!dateString) {
        return null;
    }

    if (timeZone) {
        const formattedDate = momentTimezone.tz(dateString, 'YYYY-MM-DD', timeZone);
        // Set the time to the end of the day (23:59:59)
        formattedDate.startOf('day');

        return formattedDate.valueOf(); // Returns the timestamp in milliseconds
    }

    const formattedDate = moment(dateString, 'YYYY-MM-DD');
    // Set the time to the end of the day (23:59:59)
    formattedDate.startOf('day');

    return formattedDate.valueOf(); // Returns the timestamp in milliseconds
}
/**
 * Convert date filter values based on the specified time zone or default time zone.
 *
 * This function constructs and returns an object with converted date filter values, such as 'timestamp_after' and 'timestamp_before,
 * for the specified 'from_date' and 'to_date' in the provided time zone or, if no time zone is specified, in the default time zone.
 * It uses the provided 'from_date' and 'to_date' to calculate the start and end of day timestamps in the specified time zone,
 * and constructs an object with the corresponding filter values.
 *
 * @param {string} from_date - The 'from_date' filter value in 'YYYY-MM-DD' format.
 * @param {string} to_date - The 'to_date' filter value in 'YYYY-MM-DD' format.
 * @param {string} [timeZone] - The time zone in which to perform the date conversions.
 * @returns {Object} An object with converted date filter values, including 'timestamp_after' and 'timestamp_before'.
 *
 * @example
 * const from_date = '2023-10-27';
 * const to_date = '2023-10-28';
 * const timeZone = 'America/New_York';
 * const dateFilters = convertDateFiltersDependOnHubTimeZone(from_date, to_date, timeZone);
 * // Result: { timestamp_after: 1666800000000, timestamp_before: 1666886399999 }
 */
export const convertDateFiltersDependOnHubTimeZone = (from_date, to_date, timeZone) => {
    const dateFilters = {};

    if (from_date) {
        dateFilters[TIMESTAMP_AFTER] = getStartOfDayTimestamp(from_date, timeZone);
    }

    if (to_date) {
        dateFilters[TIMESTAMP_BEFORE] = getEndOfDayTimestamp(to_date, timeZone);
    }

    return dateFilters;
};
/**
 * Get backup filters for a request, including page and date filters converted based on the specified time zone or default time zone.
 *
 * This function constructs and returns an object with backup filters suitable for a request. It includes the 'page' filter
 * as well as 'timestamp_after' and 'timestamp_before' filters that are converted based on the provided 'from_date' and 'to_date'
 * in the specified time zone or, if no time zone is specified, in the default time zone. The 'dateFilters' are constructed using
 * the 'convertDateFiltersDependOnHubTimeZone' function.
 *
 * @param {Object} filters - An object containing filters, including 'page', 'from_date', and 'to_date'.
 * @param {string} [timeZone] - The time zone in which to perform date conversions.
 * @returns {Object} An object with backup filters suitable for a request, including 'page', 'timestamp_after', and 'timestamp_before'.
 *
 * @example
 * const filters = {
 *   page: 1,
 *   from_date: '2023-10-27',
 *   to_date: '2023-10-28'
 * };
 * const timeZone = 'America/New_York';
 * const backupFilters = getBackupsFiltersForRequest(filters, timeZone);
 * // Result: { page: 1, timestamp_after: 1666800000000, timestamp_before: 1666886399999 }
 */
export const getBackupsFiltersForRequest = ({ page, from_date, to_date }, timeZone) => {
    const dateFilters = convertDateFiltersDependOnHubTimeZone(from_date, to_date, timeZone);

    return {
        page,
        ...dateFilters,
    };
};
/**
 * Get a new page number based on the current page number and page type.
 *
 * This function calculates and returns a new page number based on the current page number and the specified page type.
 * If the page type is "PREV," it returns a page number decreased by the PAGE_STEP value. If the page type is "NEXT," it
 * returns a page number increased by the PAGE_STEP value. If the type is neither "PREV" nor "NEXT," it returns the
 * current page number without any change.
 *
 * @param {number} pageNumber - The current page number.
 * @param {string} type - The page type, either "PREV" or "NEXT," to determine the direction of page navigation.
 * @returns {number} The new page number based on the specified type and the current page number.
 *
 * @example
 * const currentPage = 2;
 * const pageType = 'PREV';
 * const newPage = getNewPage(currentPage, pageType);
 * // Result: 1 (Previous page)
 *
 * const currentPage = 2;
 * const pageType = 'NEXT';
 * const newPage = getNewPage(currentPage, pageType);
 * // Result: 3 (Next page)
 *
 * const currentPage = 2;
 * const pageType = 'INVALID_TYPE';
 * const newPage = getNewPage(currentPage, pageType);
 * // Result: 2 (No change, as the type is not "PREV" or "NEXT")
 */
export const getNewPage = (pageNumber, type) => {
    if (type === PREV) {
        return pageNumber - PAGE_STEP;
    }

    if (type === NEXT) {
        return pageNumber + PAGE_STEP;
    }

    return pageNumber;
};

/**
 * Get updated filters by merging new filters with current filters.
 *
 * This function takes the current filters and new filters, and returns a new object representing the updated filters.
 * It merges the new filter values into the current filters and removes any filters with null or undefined values in
 * the new filters, effectively updating or removing filters as needed.
 *
 * @param {Object} currentFilters - The current filter object.
 * @param {Object} newFilters - The new filter object containing updated filter values.
 * @returns {Object} An object representing the updated filters after merging with the new filter values.
 *
 * @example
 * const currentFilters = { name: 'John', age: 30, city: 'New York' };
 * const newFilters = { age: null, country: 'USA' };
 * const updatedFilters = getUpdatedFilters(currentFilters, newFilters);
 * // Result: { name: 'John', city: 'New York', country: 'USA' }
 */
export function getUpdatedFilters(currentFilters, newFilters) {
    // Create a copy of the current filters to avoid modifying the original object
    const updatedFilters = { ...currentFilters };

    // Iterate through the new filters
    for (const key in newFilters) {
        if (!newFilters[key]) {
            // Remove the filter if its value is null in the new filters
            delete updatedFilters[key];
        } else {
            // Update the filter with the new value
            updatedFilters[key] = newFilters[key];
        }
    }

    return updatedFilters;
}
/**
 * Get the value of a nested property in an object based on the specified path.
 *
 * This function retrieves the value of a nested property in an object based on the specified dot-separated path.
 * If the property or any intermediate property in the path does not exist, it returns undefined.
 *
 * @param {Object} obj - The object from which to retrieve the value.
 * @param {string} path - The dot-separated path to the desired property.
 * @returns {*} The value of the specified nested property, or undefined if not found.
 *
 * @example
 * const data = {
 *   user: {
 *     profile: {
 *       name: 'John Doe',
 *       age: 30,
 *     },
 *   },
 * };
 * const value = getValueByPath(data, 'user.profile.name');
 * // Result: 'John Doe'
 */
export function getValueByPath(obj, path) {
    const pathArray = path.split('.');

    return pathArray.reduce((acc, key) => {
        if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
        } else {
            return undefined;
        }
    }, obj);
}

/**
 * Translates the hub type value obtained from a backup using the specified translation function.
 *
 * This function translates the hub type value obtained from a backup using the provided translation function.
 * If the translated key is not found, it returns the raw value or a placeholder (' - ').
 *
 * @function
 * @param {Object} backup - The backup object from which to retrieve the hub type value.
 * @param {string} propertyPath - The property path indicating the hub type in the backup.
 * @param {function} t - The translation function for translating keys into human-readable text.
 * @returns {string} The translated hub type value or hub type from request or a placeholder if the value is not found.
 *
 * @example
 * // Usage
 * const backup = { meta: { controller_info: { config: { hub_type: 'h2.1' } } } };
 * const propertyPath = 'meta.controller_info.config.hub_type';
 * const translatedValue = getHubTypeValue(backup, propertyPath, t);
 * // output: 'Ezlo Plus';
 */
const getHubTypeValue = (backup, propertyPath, t) => {
    const value = getValueByPath(backup, propertyPath) || ' - ';
    const key = HUB_NAMES_KEYS_BY_HUB_TYPE[value];

    return key ? t(key) : value;
};
/**
 * Retrieves information from a backup object based on the specified property path,
 * and translates it if needed using the provided translation function.
 *
 * @function
 * @param {Object} backup - The backup object from which to retrieve information.
 * @param {string} propertyPath - The property path indicating the desired information in the backup.
 * @param {function} t - The translation function for translating keys into human-readable text.
 * @returns {string} The translated value or the value from request by path.
 *
 * @example
 * // Usage
 * const backup = { meta: { controller_info: { config: { serial: '900014785' } } } };
 * const propertyPath = 'meta.controller_info.config.serial';
 * const translatedValue = getBackupInfoByPath(backup, propertyPath, t);
 * // output: '900014785'
 */
export const getBackupInfoByPath = (backup, propertyPath, t) => {
    if (propertyPath === PATHS.HUB_TYPE) {
        return getHubTypeValue(backup, propertyPath, t);
    }

    return getValueByPath(backup, propertyPath);
};

/**
 * Validates filters fields to ensure proper date range.
 *
 * @param {object} fields - The fields object.
 * @param {string} fields.from_date - The "From" date in the format "YYYY-MM-DD".
 * @param {string} fields.to_date - The "To" date in the format "YYYY-MM-DD".
 * @param {function} t - Translation function for localization.
 *
 * @returns {object|boolean} - If there is an error, returns an object with error messages for each field.
 *                            If no error, returns false.
 *
 * @example
 * const fields = {
 *   from_date: "2023-11-10",
 *   to_date: "2023-11-15",
 * };
 * const validationError = getFiltersFieldValidation(fields, translationFunction);
 * if (validationError) {
 *   // Handle validation error
 *   console.log(validationError);
 * }
 */
export function getFiltersFieldValidation({ from_date, to_date }, t) {
    // Check if either field is empty
    if (!from_date || !to_date) {
        return false; // No error if one or both fields are empty
    }

    // Convert strings to timestamps for comparison
    const fromTimestamp = new Date(from_date).getTime();
    const toTimestamp = new Date(to_date).getTime();

    if (fromTimestamp > toTimestamp) {
        return {
            [FROM_DATE]: t(EZLOGIC_TITLE_FROM_FIELD_ERROR_TEXT),
            [TO_DATE]: t(EZLOGIC_TITLE_TO_FIELD_ERROR_TEXT),
        };
    }

    return false;
}
