import Axios from 'axios';
import {
    otlHandleFailedResponse,
    otlHandleStartRequest,
    otlHandleSuccessResponse,
} from '../opentelemetry/otlRequestsManager/axiosManager';

Axios.interceptors.request.use((config) => {
    otlHandleStartRequest(config);

    return config;
});
Axios.interceptors.response.use(
    (response) => {
        otlHandleSuccessResponse(response);

        return response;
    },
    (error) => {
        otlHandleFailedResponse(error);

        return Promise.reject(error);
    },
);
