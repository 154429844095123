import Axios from 'axios';
import { API_CLOUD_EZLO_REQUEST_URL, KVS_GET, KVS_SET } from '../../../../constants/URLs';
import { axiosCloudConfig } from '../../../../api/config';
import { VERSIONS } from '../../../../constants/App';
import { QUERY_METHODS } from '../../../../constants/api';
import { fetch } from 'network/http';

/**
 * API that reads data from KVS
 * @param {Object} params - request params
 * @returns {Promise<Object>} request response
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=KVS+-+Get here}
 * @example
 * getKvs({list: [{ key: 'meshbot_labels', type: 'user' }]})
 * */
export const getKvs = (params) => {
    const data = {
        call: KVS_GET,
        params,
        version: VERSIONS.FIRST,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};
/**
 * API that set data to KVS
 * @param {Object} params - request params
 * @returns {Promise<Object>} request response
 * More info {@link https://confluence.mios.com/display/EPD/KVS+-+Set here}
 * @example
 * setKvs({key: 'meshbot_labels', type: 'user', value: 'ggdgvhj2568mh', public: 1})
 * */
export const setKvs = (params) => {
    const data = {
        call: KVS_SET,
        params,
        version: VERSIONS.FIRST,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

/**
 * API that set data to KVS with keepalive = true (This allows the query to survive the death of the page)
 * @param {Object} params - request params
 * @returns {Promise<Object>} request response
 * More info {@link https://confluence.mios.com/display/EPD/KVS+-+Set here}
 * fetch() with keepalive {@link https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters here}
 * @example
 * setKvsWithKeepalive({key: 'meshbot_labels', type: 'user', value: 'ggdgvhj2568mh', public: 1})
 * */
export const setKvsWithKeepalive = (params) => {
    const data = {
        call: KVS_SET,
        params,
        version: VERSIONS.FIRST,
    };

    return fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: QUERY_METHODS.POST,
        body: JSON.stringify(data),
        keepalive: true,
        ...axiosCloudConfig(),
    });
};
