import getOt from './getOt';

/**
 * Retrieves OpenTelemetry trace IDs specifically for WebSocket operations.
 *
 * Calls `getOt` with 'trace' and 'span' as keys to obtain an object containing
 * the trace ID and span ID for WebSocket tracing.
 *
 * @returns {Object|undefined} An object containing the trace ID and span ID with keys 'trace' and 'span',
 *                             or undefined if the trace IDs are unavailable.
 */
export function getWsOt() {
    return getOt('trace', 'span');
}
