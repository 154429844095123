import axios from 'axios';
import * as types from '../constants/ActionTypes/VirtualDeviceManager';
import { API_CLOUD_EZLO_REQUEST_URL, GET_VIRTUAL_CONTAINER } from '../constants/URLs';
import {
    buildAbstractCommandRequestBody,
    buildAbstractListDetailedRequestBody,
    buildVirtualDeviceListRequestBody,
    buildVirtualDeviceSetRequestBody,
} from '../containers/Ezlo/EzloVirtualDeviceManager/utils';
import { axiosCloudConfig } from '../api/config';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { toast, TOAST_TYPE } from 'components/Toast';
import { t } from 'helpers/language';
import {
    EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_SAVED,
    EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_DELETED,
} from 'constants/language_tokens';

const actions = {
    fetchVirtualDeviceList: () => (dispatch) => {
        function onError(error) {
            bugsnagNotify(error, { type: types.VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_ERROR });
            dispatch({ type: types.VDM_FETCH_VIRTUAL_DEVICE_LIST, error });
        }

        try {
            return axios
                .post(API_CLOUD_EZLO_REQUEST_URL(), buildVirtualDeviceListRequestBody(), axiosCloudConfig())
                .then((payload) => {
                    const {
                        data: { status, data },
                    } = payload;
                    if (status === 0) {
                        throw new Error(`virtual_device_list call failed. data: ${JSON.stringify(data)}`);
                    }

                    const virtualDeviceList = data.virtual_devices;
                    dispatch({ type: types.VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_SUCCESS, virtualDeviceList });
                })
                .catch(onError);
        } catch (error) {
            onError(error);
        }
    },

    deleteVirtualDevice: (params) => (dispatch) => {
        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildVirtualDeviceSetRequestBody(params), axiosCloudConfig())
            .then(({ data }) => {
                dispatch({ type: types.VDM_DELETE_VIRTUAL_DEVICE_ON_SUCCESS, uuid: data.data.uuid });
                toast(t(EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_DELETED), {
                    type: TOAST_TYPE.SUCCESS,
                });
            })
            .catch((error) => {
                bugsnagNotify(error, { type: types.VDM_DELETE_VIRTUAL_DEVICE_ON_ERROR, params });
                dispatch({ type: types.VDM_DELETE_VIRTUAL_DEVICE_ON_ERROR, error });
            });
    },

    createVirtualDevice: (params, navigate) => (dispatch) => {
        dispatch({ type: types.VDM_CREATE_VIRTUAL_DEVICE });

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildVirtualDeviceSetRequestBody(params), axiosCloudConfig())
            .then(({ data }) => {
                dispatch({ type: types.VDM_CREATE_VIRTUAL_DEVICE_ON_SUCCESS, uuid: data.data.uuid });
                dispatch(actions.fetchVirtualDeviceList());
                navigate(GET_VIRTUAL_CONTAINER());
                toast(t(EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_SAVED), {
                    type: TOAST_TYPE.SUCCESS,
                });
            })
            .catch((error) => {
                bugsnagNotify(error, { type: types.VDM_CREATE_VIRTUAL_DEVICE_ON_ERROR, params });
                dispatch({ type: types.VDM_CREATE_VIRTUAL_DEVICE_ON_ERROR, error });
            });
    },

    fetchAbstractListDetailed: () => (dispatch) => {
        dispatch({ type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED });

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildAbstractListDetailedRequestBody(), axiosCloudConfig())
            .then(({ data }) => {
                dispatch({
                    type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS,
                    abstracts: data.data.abstracts,
                });
            })
            .catch((error) => {
                bugsnagNotify(error, { type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR });
                dispatch({ type: types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR, error });
            });
    },

    selectVirtualDevice: (virtualDevice) => (dispatch) => {
        dispatch({ type: types.VDM_SELECT_VIRTUAL_DEVICE, selectedVirtualDevice: virtualDevice });
    },

    submitTextCommand: (params) => (dispatch) => {
        dispatch({
            type: types.VDM_SEND_TEXT_COMMAND,
            payload: { uuid: params.uuid, input: params.parameters.text },
        });

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildAbstractCommandRequestBody(params), axiosCloudConfig())
            .then(({ data }) => {
                const output =
                    (data.data &&
                        data.data.capabilities &&
                        data.data.capabilities.text_command &&
                        data.data.capabilities.text_command.variables &&
                        data.data.capabilities.text_command.variables.text) ||
                    'Unable to run command';
                dispatch({
                    type: types.VDM_SEND_TEXT_COMMAND_ON_SUCCESS,
                    payload: { uuid: params.uuid, output },
                });
            })
            .catch((error) => {
                bugsnagNotify(error, { type: types.VDM_SEND_TEXT_COMMAND_ON_ERROR, params });
                dispatch({ type: types.VDM_SEND_TEXT_COMMAND_ON_ERROR, payload: { uuid: params.uuid, error } });
            });
    },

    submitAbstractCommand: (params) => (dispatch) => {
        dispatch({
            type: types.VDM_SUBMIT_ABSTRACT_COMMAND,
            payload: { ...params },
        });

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildAbstractCommandRequestBody(params), axiosCloudConfig())
            .then((payload) => {
                dispatch({ type: types.VDM_SUBMIT_ABSTRACT_COMMAND_ON_SUCCESS, payload });
            })
            .catch((error) => {
                bugsnagNotify(error, { type: types.VDM_SUBMIT_ABSTRACT_COMMAND_ON_ERROR, params });
                dispatch({ type: types.VDM_SUBMIT_ABSTRACT_COMMAND_ON_ERROR, error });
            });
    },

    setVirtualDevice: (virtualDevice) => (dispatch) => {
        dispatch({ type: types.SET_VIRTUAL_DEVICE, virtualDevice });
    },

    setSavedVirtualDevice: (virtualDevice) => (dispatch) => {
        dispatch({ type: types.SET_SAVED_VIRTUAL_DEVICE, virtualDevice });
    },
};

export default actions;
