import { EVENTS, SEVERITY_TEXT, STAGES } from '../../constants';
import { log } from '../../logging';
import { getDynamicHttpLogAttributes } from '../../utils/getDynamicHttpLogAttributes';
import { SEMATTRS_HTTP_STATUS_CODE } from '@opentelemetry/semantic-conventions';
import { HTTP_STATUS_TEXT } from '../../constants/attributeNames';
import { SpanStatusCode } from '@opentelemetry/api';
import { withSafelyExecute } from '../../utils/withSafelyExecute';

function otlFetchSuccess(span, url, response) {
    const { status, statusText, responseData: data } = response;
    span.addEvent(EVENTS.RESPONSE_RECEIVED);

    log(`Request to ${url} succeeded`, SEVERITY_TEXT.INFO, {
        url,
        status,
        ...getDynamicHttpLogAttributes({ url, stage: STAGES.RESPONSE, data }),
    });

    span.setAttributes({
        [SEMATTRS_HTTP_STATUS_CODE]: status,
        [HTTP_STATUS_TEXT]: statusText,
    });
    span.setStatus({ code: SpanStatusCode.OK });
}

export default withSafelyExecute(otlFetchSuccess);
