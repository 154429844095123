import { tracer } from '../../tracers';
import { WS_BROADCAST_NAME, WS_HUB_SERIAL } from '../../constants/attributeNames';
import { log } from '../../logging';
import { HUB_BROADCAST, SEVERITY_TEXT, STATUSES } from '../../constants';
import { getDynamicWSLogAttributes } from '../../utils/getDynamicWSLogAttributes';
import { withSafelyExecute } from '../../utils/withSafelyExecute';
import { context, trace, TraceFlags } from '@opentelemetry/api';

/**
 * Handles a WebSocket broadcast message with OpenTelemetry tracing.
 *
 * @param {string} serial - The serial number of the hub.
 * @param {string} name - The name of the broadcast event.
 * @param {Array} args - The data array received from the broadcast.
 */
function broadcastWithOtl(serial, name, [data]) {
    const parentContext = trace.setSpanContext(context.active(), {
        traceId: data?.ot?.trace,
        spanId: data?.ot?.span,
        traceFlags: TraceFlags.SAMPLED,
        isRemote: true,
    });

    tracer.startActiveSpan(
        `WS ${name} broadcast from ${serial} hub`,
        { attributes: { [WS_HUB_SERIAL]: serial, [WS_BROADCAST_NAME]: name } },
        parentContext,
        (span) => {
            log(`Received WS ${name} broadcast from ${serial} hub`, SEVERITY_TEXT.INFO, {
                hub_serial: serial,
                type: HUB_BROADCAST,
                status: STATUSES.OK,
                api: name,
                ...getDynamicWSLogAttributes({ serial, data }),
            });

            span.end();
        },
    );
}

export default withSafelyExecute(broadcastWithOtl);
