import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { axiosCloudConfig } from './config';
import axios from 'axios';

/**
 * Sends 'controller_group_set' API call to Cloud
 * More info you can find here {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Controller+Group+-+Set controller_group_set}
 * @param {string} body - "controller_group_set" request body
 * @returns {Promise} API call response body
 * */
export const apiControllerGroupSet = async (body) => {
    const res = await axios.post(API_CLOUD_EZLO_REQUEST_URL(), body, axiosCloudConfig());

    return res.data;
};
